import Image from "next/image"
import { useRouter } from "next/router"
import React from "react"
import styled from "styled-components"
import { ArrowRight, StarFill, StarHalf } from "react-bootstrap-icons"
import background from "@/public/header-background.svg"
import { Button } from "@mui/material"
import translate from "@/utils/translation"
import { useSession } from "next-auth/react"

const HomebannerTest = ({ setRegisterModal }) => {
  const router = useRouter()

  const { data: session } = useSession()

  return (
    <Wrapper className="css-16h2cue">
      <div className="container">
        <div className="row m-0">
          <div className="col-md-6 col-12 textWrapper order-2 order-md-1">
            <h1 className="mt-md-5 title">
              {translate("homepage_header_subtitle_1_test")}{" "}
              <span className="highlight">
                {translate("homepage_header_subtitle_2_test")}
              </span>{" "}
              {translate("homepage_header_subtitle_3_test")}
            </h1>
            <p className="mt-3 mb-3 mt-md-4 subtitle">
              {translate("homepage_discover_description_1_test") + " "}
              <strong>{translate("homepage_header_description_2_test")}</strong>
              {" " + translate("homepage_header_description_3_test")}
            </p>
            <div className="mt-md-4 sign-up d-md-flex">
              <Button
                variant="contained"
                className="header-button w-100 rounded-pill"
                onClick={() => {
                  if (session) {
                    router.push("/listings")
                  } else {
                    setRegisterModal(true)
                  }
                }}
              >
                <p>{translate("homepage_header_primary_button_test")}</p>
                <ArrowRight className="ms-1" />
              </Button>
              <Button
                variant="outlined"
                className="header-button w-100 ms-md-3 mt-3 mt-md-0 rounded-pill"
                onClick={() => router.push("#how-it-works")}
              >
                <p>{translate("homepage_header_secondary_button_test")}</p>
              </Button>
              {/* <div className="ms-md-3 mt-2 mt-md-0 w-100 d-block">
                <Button
                  variant="outlined"
                  className="header-button border-0 w-100 rounded-pill"
                >
                  Bekijk eerdere projecten
                </Button>
              </div> */}
            </div>

            <div className="mt-2 mt-md-4 d-flex trustpilot">
              <a
                href="https://nl.trustpilot.com/review/valvestcapital.com"
                target="_blank"
                className="buy-button mt-3 d-flex"
                style={{ textDecoration: "none" }}
              >
                <Image
                  src="/trustpilot.svg"
                  alt="trustpilot score"
                  width={110}
                  height={30}
                />
                <div className="mt-1 ms-3 stars">
                  <StarFill className="ms-1" color="#FFB800" size={16} />
                  <StarFill className="ms-1" color="#FFB800" size={16} />
                  <StarFill className="ms-1" color="#FFB800" size={16} />
                  <StarFill className="ms-1" color="#FFB800" size={16} />
                  <StarHalf className="ms-1" color="#FFB800" size={16} />
                </div>
                <div>
                  <p className="ms-3 text-grey" style={{ marginTop: "8px" }}>
                    {translate("homepage_header_trustpilot")}
                  </p>
                </div>
              </a>
            </div>
          </div>
          <div className="d-block d-md-none col-md-6 text-center d-md-block col-12 position-relative order-1 order-md-2">
            <img className="main" src="/header-mobile.png" />
          </div>
          <div className="d-none d-md-block col-md-6 text-center d-md-block col-12 position-relative order-1 order-md-2">
            <img className="main" src="/header-desktop.png" />
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default HomebannerTest

const Wrapper = styled.div`
  width: 100%;
  min-height: 580px;
  width: 100%;
  height: 100%;
  background-color: white;
  position: relative;

  @media (max-width: 768px) {
  margin-top:70px;
  }

  .main{
    position: relative;
    z-index: 1;
    width:100%;

    @media (max-width: 768px) {
      width: 100%;
      margin-top: -2.5rem;
      margin-right: 0rem;
      margin-left:-1rem;
    }
  }

  .showcase {
    color: #FB8500;
    text-decoration: none;
    font-size: 0.875rem;  
  }

  .sign-up {
    .MuiInputBase-root {
      max-height: 45px;
      height:45px;
      border-radius:20px;
      width:240px;
      background:white;

      @media (max-width: 768px) {
        width: 100%;
      }
    }

    .header-button {
      max-height: 45px;
      height:45px;

      p {
      font-weight: 500;
        vertical-align: middle;
        padding: 0;
        margin: 0;
        margin-bottom:0.1rem;
      }

      @media (max-width: 768px) {
        width: 100%;
        min-width: 100%;
      }
    }

    max-width:450px;
  }


    h1 {
      font-size: 2.6rem;
      line-height: 3.6rem;
      max-width: 500px;
      font-weight: 700;
    }
  }

  ul {
    margin-top: 2rem;
    margin-bottom: 1rem;
    margin-left: 0;
    list-style: none;
    margin-left: -1.5rem;
    font-size: 1rem;
    font-weight: 300;

    li {
      margin-bottom: 0.4rem;
      color: black;
    }
  }

  .prospectus p{
    margin-top: 1rem;
    font-size: 0.875rem;
    font-weight:300;
    color: #585858;
    margin-bottom:0;
    text-decoration: underline;

  }

  .highlight {
    color: #fb8500;
    opacity: 1;
  }

  :before {
    content: "";
    background-size: cover;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0.8;
    z-index: 1;

    background-image: url(${background.src});
    background-repeat: no-repeat;
  }

  .sell-button {
    margin-left: 1rem;
    border: 1px solid black;
    color: black;

    :hover {
      background-color: black;
      color: white;
      border: 1px solid black;
    }
  }

  .trustpilot p{
    text-decoration:none;
    -webkit-text-decoration:none;
    color:#17233b;
  }

  .textWrapper {
    margin-top: 3.5rem;
    margin-bottom:2rem;
    z-index:1;
  }

  .subtitle {
    max-width: 500px;
    font-size: 1.1rem;
    font-weight:300;
    padding: 0 0 0.5rem;
    line-height: 1.6rem;
  }

  .topheader {
    font-size: 0.875rem;
    color: #fb8500;
    font-weight: 400;
    margin-bottom: 1.125rem;
  }

  @media (max-width: 992px) {

    h1 {
      font-size: 2.2rem;
    }
  }

  @media (max-width: 768px) {
    .trustpilot {
      justify-content: center;
    }


  .textWrapper {
    margin-top: -4rem;

  }

    min-height: 400px;

    padding-top: 0rem;


    .stars {
      margin-left: 0.6rem !important;
    }

    h1 {
      font-size: 2.5rem;
      line-height: 3rem;
      font-weight:700;
      
    }

    .subtitle {
      font-size: 1.1rem;
    font-weight:300;
    line-height: 1.6rem;
    }

    .buy-button {
      width: 100%;
      display: block;
      padding: 0rem;
    }

    .sell-button {
      width: 100%;
      display: block;
      margin-left: 0;
      margin-top: 1rem;
      background-color: white;
      padding: 1rem;
    }
  }

  @media (max-width: 550px) {
    h1 {
      font-size: 2rem;
      line-height: 2.4rem;
      font-weight:700;
    }

    .textWrapper {
      margin-bottom:0rem !important;
      margin-top: -3rem;

      p{
        font-size: 1rem;
        font-weight:300;
        }

    }
  }

  @media (max-width: 400px) {

    h1 {
        font-size: 1.8rem;
        line-height: 2.4rem;
    }

    .subtitle{
      font-size: 1.1rem;
      margin-top:0.75rem !important;
    }


    margin-bottom;3rem;

  }
`
